import { createSlice } from "@reduxjs/toolkit";

const messageSlice = createSlice({
    name: "messages",
    initialState: {
        messages: [],
        message: [],
        selectedChat: null,
        loading: false,
        unreadMessageCount: 0, // Track the number of unread messages
    },
    reducers: {
        setMessages: (state, action) => {
            state.messages = action.payload;
            // Recalculate unread message count whenever messages are set
            state.unreadMessageCount = recalculateUnreadMessagesCount(state.messages);
        },
        setMessage: (state, action) => {
            state.message = action.payload;
            // Recalculate unread message count whenever messages are set
            // state.unreadMessageCount = recalculateUnreadMessagesCount(state.messages);
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        selectChat: (state, action) => {
            state.selectedChat = action.payload;
        },
        updateChatSidebar: (state, action) => {
            const newChat = action.payload;
            const newMessage = newChat.messages[0];

            // Check if the chat already exists based on receiverId or senderId
            const existingChatIndex = state.messages.findIndex(chat => {
                return chat.id == newMessage.receiverId || chat.id == newMessage.senderId;
            });

            if (existingChatIndex > -1) {
                // Chat exists, update it
                state.messages[existingChatIndex] = {
                    ...state.messages[existingChatIndex],
                    messages: [
                        ...state.messages[existingChatIndex].messages,
                        ...newChat.messages // Add new messages to the existing chat
                    ]
                };
            } else {
                state.messages.push(newChat);
            }

            // Recalculate unread message count after adding new chat/messages
            state.unreadMessageCount = recalculateUnreadMessagesCount(state.messages);
        },
        addMessage: (state, action) => {
            const newMessage = action.payload;

            // Update the selected chat if there is one
            if (state.selectedChat) {
                const updatedChat = {
                    ...state.selectedChat,
                    messages: [...state.selectedChat.messages, newMessage]
                };

                // Update the selected chat in the messages array
                state.messages = state.messages.map(chat =>
                    chat.id === state.selectedChat.id ? updatedChat : chat
                );

                // Update the selected chat in the state
                state.selectedChat = updatedChat;
            } else {
                // If there's no selected chat, simply add to the messages array
                const chatIndex = state.messages.findIndex(chat => chat.id === newMessage.chatId);
                if (chatIndex > -1) {
                    state.messages[chatIndex].messages.push(newMessage);
                } else {
                    state.messages.push({ id: newMessage.chatId, messages: [newMessage] });
                }
            }

            // Recalculate unread message count after adding a new message
            state.unreadMessageCount = recalculateUnreadMessagesCount(state.messages);
        },
        updateLastSeen: (state, action) => {
            const { id, lastSeen, online } = action.payload;
            const sidebarChatIndex = state?.messages?.findIndex(chat => chat.id === id);
            if (sidebarChatIndex > -1) {
                state.messages[sidebarChatIndex] = {
                    ...state.messages[sidebarChatIndex],
                    lastSeen,
                    online,
                };
            }

            if (state.selectedChat && state.selectedChat.id === id) {
                state.selectedChat = {
                    ...state.selectedChat,
                    lastSeen,
                    online,
                };
            }
        },
        markMessagesAsRead: (state, action) => {
            const { readMessages } = action.payload;
            const currentUser = action.payload.currentUser; // Get the current user from the payload           
            // Iterate through all chats in the messages array
            state.messages.forEach(chat => {
                // Iterate through all messages within the current chat
                chat.messages.forEach(message => {
                    // Check if the message belongs to the current user and if it's in the readMessages
                    if (
                        message.receiverId === currentUser?.id && // Current user is the receiver
                        readMessages.some(unreadMessage => unreadMessage.id === message.id) // This message is being marked as read
                    ) {
                        message.read = true; // Mark the message as read
                    }
                });
            });

            // Recalculate unread message count after marking messages as read
            state.unreadMessageCount = recalculateUnreadMessagesCount(state.messages);
        }
    },
});

// Helper function to calculate the number of unread messages (not of type "sent")
const recalculateUnreadMessagesCount = (messages) => {
    return messages.reduce((count, chat) => {
        return count + chat.messages.filter(message =>
            !message.read && message.type !== 'sent' // Only count unread messages that are not of type "sent"
        ).length;
    }, 0);
};

export const {
    setMessages,
    setMessage,
    setLoading,
    selectChat,
    addMessage,
    updateLastSeen,
    markMessagesAsRead,
    updateChatSidebar,
} = messageSlice.actions;

export default messageSlice.reducer;

export const selectMessages = (state) => state.messages.messages;
export const selectMessage = (state) => state.messages.message;
export const selectLoading = (state) => state.messages.loading;
export const selectSelectedChat = (state) => state.messages.selectedChat;
export const selectUnreadMessageCount = (state) => state.messages.unreadMessageCount; // Selector for unread count
