import { createSlice } from "@reduxjs/toolkit";

const paypalSlice = createSlice({
    name: "paypal",
    initialState: {
        paypals: [],

    },
    reducers: {
        setPaypals: (state, action) => {
            state.paypals = action.payload;
        },
        addPaypal: (state, action) => {
            state.paypals.push(action.payload);
        },
        updatePaypal: (state, action) => {
            const { id, updatedData } = action.payload;
            const paypalIndex = state.paypals.findIndex(paypal => paypal.id === id);
            if (paypalIndex !== -1) {
                state.paypals[paypalIndex] = { ...state.paypals[paypalIndex], ...updatedData };
            }
        },
        deletePaypal: (state, action) => {
            const id = action.payload;
            state.paypals = state.paypals.filter(paypal => paypal.id !== id);
        },
    },
});

export const {
    setPaypals,
    addPaypal,
    updatePaypal,
    deletePaypal
} = paypalSlice.actions;

export default paypalSlice.reducer;

export const selectPaypals = (state) => state.paypal.paypals;
