import { createSlice } from "@reduxjs/toolkit";

const withdrawalSlice = createSlice({
    name: "withdrawal",
    initialState: {
        withdrawals: [],
        companies: null,
    },
    reducers: {
        // Create: Add a new withdrawal to the list
        addWithdrawal: (state, action) => {
            state.withdrawals.push(action.payload);
        },

        // Read: Set all withdrawals
        setWithdrawals: (state, action) => {
            state.withdrawals = action.payload;
        },

        // Update: Modify an existing withdrawal by its ID
        updateWithdrawal: (state, action) => {
            const index = state.withdrawals.findIndex(
                (withdrawal) => withdrawal.id === action.payload.id
            );
            if (index !== -1) {
                // Update the withdrawal status (approved or rejected)
                state.withdrawals[index] = {
                    ...state.withdrawals[index],
                    status: action.payload.status
                };
            }
        },

        // Delete: Remove a withdrawal by its ID
        deleteWithdrawal: (state, action) => {
            state.withdrawals = state.withdrawals.filter(
                (withdrawal) => withdrawal.id !== action.payload
            );
        },

        // Set companies
        setCompanies: (state, action) => {
            state.companies = action.payload;
        },
    },
});

export const {
    addWithdrawal,
    setWithdrawals,
    updateWithdrawal,
    deleteWithdrawal,
    setCompanies,
} = withdrawalSlice.actions;

export default withdrawalSlice.reducer;

export const selectWithdrawals = (state) => state.withdrawal.withdrawals;
export const selectCompanies = (state) => state.withdrawal.companies;
